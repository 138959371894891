import React, { FunctionComponent, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Language } from "../../enums/Language";
import "./NavBar.css";

const NavBar: FunctionComponent = () => {
  const [isActive, setIsActive] = useState(false);
  const [isNavButtonActive, setIsNavButtonActive] = useState(false);
  const location = useLocation();
  const { i18n } = useTranslation();
  const [lang, setLang] = useState<Language>(i18n.language as Language);
  const [strTrad, setStrTrad] = useState("");
  const { t } = useTranslation();
  const [language, setLanguage] = useState(Language.EN);

  useEffect(() => {
    if (strTrad === "FR"){
      setStrTrad("EN");
    }
    else if (strTrad === "EN"){
      setStrTrad("FR");
    } else {
      const lang=navigator.language.substr(0,2).toUpperCase();
      if (lang === 'FR'){
        setStrTrad("EN");
      } else {
        setStrTrad("FR");
      }
    }
    
    const resize = (): void => {
      if (window.innerWidth > 800) {
        setIsNavButtonActive(false);
        setIsActive(true);
      } else {
        setIsNavButtonActive(true);
        setIsActive(false);
      }
    };

    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const changeLanguage = () => {
    if (language == Language.FR) {
      setLanguage(Language.EN);
      setStrTrad("EN");
    } else if (language == Language.EN) {
      setLanguage(Language.FR);
      setStrTrad("FR");
    }

    switch (language) {
      case Language.EN:
        setLang(Language.EN);
        i18n.changeLanguage(Language.EN);
        break;
      case Language.FR:
      default:
        setLang(Language.FR);
        i18n.changeLanguage(Language.FR);
        break;
    }
  };

  const handleClick = (): void => {
    setIsActive(!isActive);
  };

  return (
    <header className="navbar">
      {isNavButtonActive && (
        <div className="mobile-navbar">
          <button
          onClick={handleClick}
          className="noselect"
          data-testid="nav-btn"
        >
          <span>|</span>
          <span>|</span>
          <span>|</span>
        </button>
        <Link to="/" className="link noselect">
          <h1>{t("common.gepix")}</h1><span>{t("navbar.descr")}</span>
        </Link>
      </div>
      )}

      {isActive && (
        <nav>
          <Link
            to="/clients"
            style={{
              textDecoration: `${
                location.pathname === "/clients" ? "underline" : "none"
              }`,
            }}
            className="link noselect"
          >
            {t("navbar.clients")}
          </Link>

          <Link
            to="/services"
            style={{
              textDecoration: `${
                location.pathname === "/services" ? "underline" : "none"
              }`,
            }}
            className="link noselect"
          >
            {t("navbar.services")}
          </Link>
          <Link
            to="/technologie"
            style={{
              textDecoration: `${
                location.pathname === "/technologie" ? "underline" : "none"
              }`,
            }}
            className="link noselect"
          >
            {t("navbar.technology")}
          </Link>
          
          <span className="link switch noselect" onClick={changeLanguage}>
            {strTrad}
          </span>
        </nav>
      )}
      
      {!isNavButtonActive &&
        <Link to="/" className="link noselect">
          <div><h1>{t("common.gepix")}</h1><span>{t("navbar.descr")}</span></div>
        </Link>
      }
    </header>
  );
};

export default NavBar;
