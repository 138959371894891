import React, { ReactElement } from "react";
import "./App.css";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Services from "./pages/services/Services";
import Technology from "./pages/technology/Technology";
import Client from "./pages/clients/Client";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/footer/Footer";
import "./i18n";

function App(): ReactElement {
  return (
    <Router>
      <NavBar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/technologie">
          <Technology />
        </Route>
        <Route exact path="/services">
          <Services />
        </Route>
        <Route exact path="/clients">
          <Client />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
