import React, { FunctionComponent } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import Card from "../../components/card/Card";
import "./Services.css";
import geplog_fr from "../../assets/img/fr/geplog.png";
import geplog_en from "../../assets/img/en/geplog.png";

const Services: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="logo-geplog"> <img className="logo-geplog-service" src={i18n.language === 'en'? geplog_en : geplog_fr} /></div>
      
      <div className="services">
        <Card
          title={t("services.t-pilotage")}
          content={t("services.p-pilotage-1")}
          content2={t("services.p-pilotage-2")}
          content3={t("services.p-pilotage-3")}
        />
        <Card
          title={t("services.t-perso")}
          content={t("services.p-perso-1")}
          content2={t("services.p-perso-2")}
          content3={t("services.p-perso-3")}
        ></Card>
        <Card
          title={t("services.t-formation")}
          content={t("services.p-formation-1")}
          content2={t("services.p-formation-2")}
          content3={t("services.p-formation-3")}
        />
        <Card
          title={t("services.t-maintenance")}
          content={t("services.p-maintenance-1")}
          content2={t("services.p-maintenance-2")}
          content3={t("services.p-maintenance-3")}
        ></Card>
      </div>
    </div>
  );
};

export default Services;
