import React, { FunctionComponent } from "react";
import "./Technology.css";
import schema_fr from "../../assets/img/fr/tecgeplog.png";
import schema_en from "../../assets/img/en/tecgeplog.png";
import opm from "../../assets/img/oracle-partner.png";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
const Technology: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="technology">
      <div className="r1">
        <div>
          <h1>{t("technology.t-oracle")}</h1>
          <p>{t("technology.p-oracle")}</p>
        </div>
        <div>
          <h1>{t("technology.t-3tiers")}</h1>
          <p>{t("technology.p-3tiers")}</p>
        </div>
      </div>
      <div className="schema">
        <img className="shema-geplog" src={i18n.language === 'en'? schema_en : schema_fr} alt="schema geplog" />
      </div>

      <div className="r2">
        <div>
          <h1>{t("technology.t-securite")}</h1>
          <p>{t("technology.p-securite")}</p>
        </div>
        <div>
          <h1>{t("technology.t-ouvert")}</h1>
          <p>{t("technology.p-ouvert")}</p>
        </div>
      </div>
      <div className="r4">
        <img src={opm} alt="logo partner oracle" />
      </div>
    </div>
  );
};

export default Technology;
