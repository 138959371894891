import React, { FunctionComponent } from "react";
import "./Card.css";

interface props {
  title: string;
  content: string;
  content2: string;
  content3: string;
}

const Card: FunctionComponent<props> = ({ title, content, content2, content3 }: props) => {
  return (
    <div className="card">
      <h1>{title}</h1>
      <p>{content}<span className="gep-bold">{content2}</span>{content3}</p>
    </div>
  );
};

export default Card;
