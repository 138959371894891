import React, { FunctionComponent, useEffect } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import "./Home.css";
import gepix1_fr from "../../assets/img/fr/pc.png";
import gepix2_fr from "../../assets/img/fr/tel.png";
import gepix1_en from "../../assets/img/en/pc.png";
import gepix2_en from "../../assets/img/en/tel.png";
import opm from "../../assets/img/oracle-partner.png";

const Home: FunctionComponent = () => {
  const { t } = useTranslation();
  
  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }
  
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div>
      <div className="home-gepix-height">
        {
          (window.innerWidth <= 1200)
            && 
          <div>
            <div className="home-legend home-div-tit">
              <h1 className="home-div-h1">{t("common.gepix")}</h1>
              <p>
                {t("home.descr-gepix")}
              </p>
            </div>

            <div className="home-legend home-div-tit">
              <h1 className="home-div-h1">{t("common.geplog")}</h1>
              <ul>
                <li>{t("home.liste.configurateur")}</li>
                <li>{t("home.liste.gestion-com")}</li> 
                <li>{t("home.liste.achats")}</li> 
                <li>{t("home.liste.prod")}</li> 
                <li>{t("home.liste.stock")}</li> 
                <li>{t("home.liste.ged")}</li>
                <li>{t("home.liste.qualite")}</li>
                <li>{t("home.liste.kpi")}</li>
                <li>{t("home.liste.mob-com")}</li>
                <li>{t("home.liste.mob-ate")}</li>
              </ul>
            </div>
          </div>
        } 
        
        <div className="home-gepix-left">
          <div className="home-gepix-img">
            <img src={i18n.language === 'en'? gepix1_en : gepix1_fr} />
            <img src={i18n.language === 'en'? gepix2_en : gepix2_fr} />
          </div>
        </div>
        
        {
          (window.innerWidth > 1200)
            && 
          <div>
            <div className="home-legend home-div-tit">
              <h1 className="home-div-h1">{t("common.gepix")}</h1>
              <p>
              {t("home.descr-gepix")}
              </p>
            </div>

            <div className="home-legend home-div-tit">
              <h1 className="home-div-h1">{t("common.geplog")}</h1>
              <ul>
                <li>{t("home.liste.configurateur")}</li>
                <li>{t("home.liste.gestion-com")}</li> 
                <li>{t("home.liste.achats")}</li> 
                <li>{t("home.liste.stock")}</li> 
                <li>{t("home.liste.prod")}</li>
                <li>{t("home.liste.ged")}</li>
                <li>{t("home.liste.edi")}</li>
                <li>{t("home.liste.qualite")}</li>
                <li>{t("home.liste.kpi")}</li>
                <li>{t("home.liste.sav")}</li>
                <li>{t("home.liste.mob-com")}</li>
                <li>{t("home.liste.mob-ate")}</li>
              </ul>
            </div>
          </div>
        }
      </div>
      <div className="home-logo-oracle"><img src={opm} className="home-gepix-img-oracle" /></div>
    </div>
  );
};

export default Home;
