import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <footer>
      <Link to="/" className="logo">
        <h1>{t("common.gepix")}</h1>
      </Link>
      <section>
        <nav>
        <Link to="/clients">{t("navbar.clients")}</Link>
          <Link to="/services">{t("navbar.services")}</Link>
          <Link to="/technologie">{t("navbar.technology")}</Link>
        </nav>

        <div className="contact">
          <p> GEPIX sarl - 9c rue Adolphe Hirn - 68124 LOGELBACH FRANCE / Capital : 50 000 euros</p>
          <p>{t("footer.phone")} : +33 (0) 3 89 30 23 30 </p>
          <p>{t("footer.N° Intracom")} : FR95380879775 / RCS : COLMAR B 380 879 775 </p>
          <p>{t("footer.director of publication")} : Alain Zippert</p>
          <p>{t("footer.Hosting of the site")} : OVH 2 rue Kellermann 59100 Roubaix</p>
          <p></p>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
