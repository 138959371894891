import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Client.css";
import rs1 from "../../assets/img/rs-sakura.jpg";
import rs2 from "../../assets/img/rs-descarte.jpg";
import ast1 from "../../assets/img/ast-auto2.jpg";
import ast2 from "../../assets/img/ast-auto3.png";
import sils1 from "../../assets/img/optique.jpg";
import sils2 from "../../assets/img/optique2.jpg";
import mistral1 from "../../assets/img/mistral-reseaux.jpg";
import mistral2 from "../../assets/img/mistral-serveurs.jpg";
import impr1 from "../../assets/img/Imprimante_3D.png";
import impr2 from "../../assets/img/Rhino_3D.png";

const Client: FunctionComponent = () => {
  const { t } = useTranslation();

  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }
  
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className="gepix-height">
        <div className="gep-ligne">
            <div className="div-tit">
              <h1 className="div-h1">{t("clients.ophtalmie")}</h1>
            </div>

          <div className="gepix-left">
            <img src={sils2} />
            <img src={sils1} />
          </div>
        </div>
        
        <div className="gep-ligne">
            <div className="div-tit">
              <h1 className="div-h1">{t("clients.telecom")}</h1>
            </div>

          <div className="gepix-left">
            <img src={mistral1} />
            <img src={mistral2} />
          </div>
        </div>

        <div className="gep-ligne">
            <div className="div-tit">
              <h1 className="div-h1">{t("clients.automobile")}</h1>
            </div>

          <div className="gepix-left">
            <img src={ast1} />
            <img src={ast2} />
          </div>
        </div>
        
        <div className="gep-ligne">
            <div className="div-tit">
              <h1 className="div-h1">{t("clients.btp")}</h1>
            </div>
          
          <div className="gepix-left">
            <img src={rs2} />
            <img src={rs1} />
          </div>
        </div>

        <div className="gep-ligne">
            <div className="div-tit">
              <h1 className="div-h1">{t("clients.impression-3d")}</h1>
            </div>

          <div className="gepix-left">
            <img src={impr1} />
            <img src={impr2} />
          </div>
        </div>
    </div>
  );
};

export default Client;
